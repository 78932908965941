<template>
	<v-container fluid>
		<v-app-bar app color="dark" dark>
			<div class="d-flex align-center" @click="$router.push('/')">
				<v-img
					alt="Vuetify Logo"
					class="shrink mr-2"
					contain
					src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
					transition="scale-transition"
					width="40"
				/>

				<v-img
					alt="Vuetify Name"
					class="shrink mt-1 hidden-sm-and-down"
					contain
					min-width="100"
					src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
					width="100"
				/>
			</div>

			<v-spacer></v-spacer>

			<v-btn to="about" text>
				<span>About</span>
			</v-btn>

			<v-btn to="register" text>
				<span>Register</span>
			</v-btn>

			<v-btn to="login" light>
				<span>Login</span>
			</v-btn>
		</v-app-bar>
		<v-main>
			<slot></slot>
		</v-main>
	</v-container>
</template>

<script>
export default {
	methods: {
		logout() {
			this.$store.dispatch("update_user_token", null);
			this.$router.push("/login");
		},
	},
};
</script>

<style></style>
