import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		userToken: "",
		userData: null,
		PreviousRoute: null,
		Organization: null,
		Mailboxes: null,
		usersInOrg: null,
		types: null,
	},
	mutations: {
		UPDATE_USER_TOKEN: (store, data) => {
			if (data == null) {
				delete localStorage.userToken;
				delete localStorage.userSessionTimeout;
				store.userToken = null;
				return;
			}
			localStorage.userToken = data;

			if (data == "" || data == null) {
				console.log("Dispatch UPDATE_USER_TOKEN was set to null or empty");
				//IF WE ARE CLEARING THE TOKEN CLEAR THE LOCAL STORAGE
				localStorage.removeItem("userToken");
				localStorage.removeItem("userSessionTimeout");
				return;
			}

			var firstDay = new Date(); //GET TODAY'S DATE
			var nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000); //SET THE EXPERATION TO BE ONE WEEK FROM TODAY
			localStorage.userSessionTimeout = nextWeek;

			store.userToken = data;
			try {
				store.userid = atob(atob(data).split("Basic")[1]).split(":")[0];
			} catch (ex) {
				console.warn("Cannot extract UID");
			}
		},
		UPDATE_USER: (store, data) => {
			store.userData = data;
		},
		SETPREVIOUSROUTE(store, data) {
			store.PreviousRoute = data;
		},
		SETORGANIZATION(store, data) {
			if (!data.Mailboxes) {
				data.Mailboxes = null;
			}
			store.Organization = data;
			localStorage.organization = JSON.stringify(data);
		},
		SETMAILBOXES(store, data) {
			for (let i = 0; i < data.length; i++) {
				data[i].text = `${data[i].name} (${data[i].extensionid})`;
			}
			store.Mailboxes = data;
		},
		SETUSERS(store, data) {
			store.usersInOrg = data;
		},
		SETTYPES(store, data) {
			store.types = data;
		},
	},
	actions: {
		update_user_token: (context, data) => {
			context.commit("UPDATE_USER_TOKEN", data);
		},
		update_user_data: (context, data) => {
			context.commit("UPDATE_USER", data);
			if (!data) return;
			if (data.organizations.length <= 1) {
				context.dispatch("setOrganization", data.organizations[0]);
			}
		},
		previousRoute: (context, from) => {
			context.commit("SETPREVIOUSROUTE", from);
		},
		setOrganization: async (context, details) => {
			context.commit("SETORGANIZATION", details);

			let res = await axios.get(
				`https://api.ticketvm.com/organization/${details.id}/mailbox/my`
			);
			context.dispatch("setMailboxes", res.data);

			context.dispatch("fetchUsers", details.id);

			res = await axios.get(
				`https://api.ticketvm.com/organization/${details.id}/types`
			);
			let data = res.data;
			data.unshift({
				id: null,
				title: "Not Set",
			});
			context.dispatch("setTypes", data);
		},
		fetchUsers: async (context, OrgID) => {
			let res = await axios.get(
				`https://api.ticketvm.com/organization/${OrgID}/users`
			);
			let data = res.data;
			data.unshift({
				Email: null,
				FirstName: null,
				LastName: null,
				Name: "Unassigned",
				Organization: OrgID,
				Phone: null,
				Role: null,
				id: null,
			});
			context.dispatch("setUsers", data);
		},
		setMailboxes: (context, details) => {
			context.commit("SETMAILBOXES", details);
		},
		setUsers: (context, details) => {
			context.commit("SETUSERS", details);
		},
		setTypes: (context, details) => {
			context.commit("SETTYPES", details);
		},
	},
	modules: {},
});
