<template>
	<v-app>
		<Main
			:class="['/login', '/pricing'].includes($route.path) ? 'bgrnd' : ''"
			v-if="!isLoggedIn"
		>
			<v-scroll-y-transition>
				<router-view />
			</v-scroll-y-transition>
		</Main>

		<LoggedIn
			:class="['/login', '/pricing'].includes($route.path) ? 'bgrnd' : ''"
			v-if="isLoggedIn"
		>
			<v-scroll-y-transition>
				<router-view />
			</v-scroll-y-transition>
		</LoggedIn>
	</v-app>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Main from "./components/headerFooters/Main.vue";
import LoggedIn from "./components/headerFooters/LoggedIn";
export default {
	components: { Main, LoggedIn },
	name: "App",
	computed: {
		...mapState(["userData", "userToken"]),
		isLoggedIn() {
			// eslint-disable-next-line
			return !!this.userData && !!this.userToken;
		},
	},
	created() {
		if (localStorage.userToken) {
			if (
				Date.parse(localStorage.userSessionTimeout) - Date.parse(new Date()) >
				0
			) {
				//USER SESSION IS STILL VALID SO LOAD CREDENTIALS
				this.$store.dispatch("update_user_token", localStorage.userToken); //SET THE USER TOKEN
				axios.get(`https://api.ticketvm.com/users`).then((data) => {
					this.$store.dispatch("update_user_data", data.data);
				});
			} else {
				//USER SESSION HAS EXPIRED
				this.$store.dispatch("update_user_token", null); //CLEAR USER TOKEN
				this.$router.push("Login");
			}
		}
	},
	data: () => ({
		//
	}),
};
</script>
<style scoped>
@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.bgrnd {
	background: url("/tape.webp");
	animation: fadein 1s;
	background-size: cover;
}
</style>
