import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import VueCardFormat from "vue-credit-card-validation";

Vue.use(VueCardFormat);

Vue.directive("mask", VueMaskDirective);
Vue.config.productionTip = false;
Vue.prototype.$ServerURI = "https://api.ticketvm.com";

axios.interceptors.request.use(
	function(config) {
		config.headers = {
			Authorization: atob(store.state.userToken),
		};

		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
