<template>
	<div>
		<h1>test</h1>
	</div>
</template>

<script>
// import HelloWorld from "../components/HelloWorld";

export default {
	name: "Home",

	components: {
		// HelloWorld,
	},
};
</script>
