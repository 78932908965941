<template>
	<v-container>
		<v-app-bar app color="dark" dark>
			<div class="d-flex align-center" @click="$router.push('/')">
				<v-img
					alt="Vuetify Logo"
					class="shrink mr-2"
					contain
					src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
					transition="scale-transition"
					width="40"
				/>

				<v-img
					alt="Vuetify Name"
					class="shrink mt-1 hidden-sm-and-down"
					contain
					min-width="100"
					src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
					width="100"
				/>
			</div>

			<v-spacer></v-spacer>
			<div v-if="$store.state.userData">
				<v-menu offset-y v-if="$store.state.userData.organizations.length > 1">
					<template v-slot:activator="{ on, attrs }">
						<v-btn text dark v-bind="attrs" v-on="on">
							Current Organization:
							{{
								$store.state.Organization ? $store.state.Organization.Title : ""
							}}
						</v-btn>
					</template>
					<v-list>
						<v-list-item
							v-for="(item, index) in $store.state.userData.organizations"
							:key="index"
							@click="updateOrganization(item)"
						>
							<v-list-item-title>
								{{ item.Title }}
								<v-icon
									color="primary"
									v-if="$store.state.Organization.id == item.id"
								>
									mdi-check-circle
								</v-icon>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
			<v-spacer></v-spacer>

			<v-btn to="/manage" text>
				<span>Manage</span>
			</v-btn>

			<v-btn to="/tickets" text>
				<span>Tickets</span>
			</v-btn>

			<v-btn @click="logout" light>
				<span>Logout</span>
			</v-btn>
		</v-app-bar>

		<slot></slot>
	</v-container>
</template>

<script>
// eslint-disable-next-line
import axios from "axios";
export default {
	methods: {
		logout() {
			this.$store.dispatch("update_user_token", null);
			this.$router.push("/login");
		},
		updateOrganization(org) {
			this.$store.dispatch("setOrganization", org);
		},
	},
	mounted() {},
};
</script>

<style></style>
