import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "*",
		name: "404",
		meta: {
			AppHeadFoot: "None",
			NoAuth: true,
		},
		component: () => import("../views/404.vue"),
	},
	{
		path: "/",
		name: "Home",
		meta: {
			AppHeadFoot: "None",
			NoAuth: true,
		},
		component: Home,
	},
	{
		path: "/login",
		name: "Login",
		meta: {
			NoAuth: true,
		},
		component: () => import("../views/Login.vue"),
	},
	{
		path: "/invite/accept/:acceptID/:orgid",
		name: "Accept Invite",
		meta: {
			AppHeadFoot: "None",
			NoAuth: true,
		},
		component: () => import("../views/AcceptInvite.vue"),
	},
	{
		path: "/manage",
		name: "Manage Organization",
		meta: {
			requireAdmin: true,
		},
		component: () => import("../views/Manage/Organization.vue"),
	},
	{
		path: "/manage/billing",
		name: "Manage Billing",
		meta: {
			requireAdmin: true,
		},
		component: () => import("../views/Manage/Billing.vue"),
	},
	{
		path: "/manage/types",
		name: "Manage Types",
		meta: {
			requireAdmin: true,
		},
		component: () => import("../views/Manage/Types.vue"),
	},
	{
		path: "/manage/agents",
		name: "Manage Agents",
		meta: {
			requireAdmin: true,
		},
		component: () => import("../views/Manage/Agents.vue"),
	},
	{
		path: "/register",
		name: "Register",
		meta: {
			NoAuth: true,
		},
		component: () => import("../views/Register.vue"),
	},
	{
		path: "/pricing",
		name: "Pricing",
		meta: {
			NoAuth: true,
		},
		component: () => import("../views/Pricing.vue"),
	},
	{
		path: "/tickets",
		name: "Tickets",
		component: () => import("../views/Tickets.vue"),
	},
	{
		path: "/ticket/:mbid/:ticketid",
		name: "Ticket",
		component: () => import("../views/Ticket.vue"),
	},
	{
		path: "/about",
		name: "About",
		meta: {
			NoAuth: true,
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/About.vue"),
	},
];

const router = new VueRouter({
	routes,
	mode: "history",
	scrollBehavior() {
		// THIS FUNCTION CAN ACCEPT to, from, savedPosition
		return { x: 0, y: 0 };
	},
});

const DEFAULT_TITLE = "Ticket VM";
router.afterEach((to) => {
	Vue.nextTick(() => {
		if (to.name) {
			if (to.name != "Home") document.title = `${to.name} | ${DEFAULT_TITLE}`;
			else document.title = `Ticket Voicemail`;
		} else document.title = `${DEFAULT_TITLE}`;
	});
});

router.beforeEach(async (to, from, next) => {
	//Save last route for 404 page to analyze
	let fromDetails = {
		fullPath: from.fullPath,
		headerFooter: from.meta?.AppHeadFoot,
		path: from.path,
		hash: from.hash,
	};
	store.dispatch("previousRoute", JSON.parse(JSON.stringify(fromDetails)));
	if (to.matched.some((record) => record.meta.NoAuth)) {
		next();
		return;
	}

	//Don't do auth check for noauth routes
	if (to.meta.NoAuth) {
		next();
		return;
	}

	//CHECK IF USER SESSION HAS EXPIRED IF IT HAS THEN CLEAR THE TOKEN AND FORCE RELOGIN
	var token_expired =
		Date.parse(localStorage.userSessionTimeout) - Date.parse(new Date()) < 0;
	var token_null =
		localStorage.userToken == null || localStorage.userToken == undefined;
	var no_token_session =
		localStorage.userSessionTimeout == null ||
		localStorage.userSessionTimeout == undefined;
	if (token_expired && !(token_null || no_token_session))
		store.dispatch("update_user_token", null); //CLEAR USER TOKEN
	if (token_expired || token_null || no_token_session) {
		if (to.path == "/login") {
			next();
			return;
		}
		next({
			path: "/login",
		});
		return null;
	} else {
		if (localStorage.organization && !store.state.Organization) {
			let d = JSON.parse(localStorage.organization);
			await store.dispatch("setOrganization", d);
		}
	}

	//Check if route requires admin
	if (to.meta.requireAdmin) {
		if (store.state.userData.Role.toLowerCase() == "admin") {
			next();
		} else {
			next({
				path: "/404",
			});
		}
	} else {
		next();
	}
});

export default router;
